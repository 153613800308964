var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('hr'),_c('b-nav',{attrs:{"card-header":"","tabs":""}},[_c('b-nav-item',{attrs:{"active":_vm.subStatus == 'standar' ? true : false,"to":{
                name: ("pemeriksaanPemeriksaan" + (_vm.capitalizeFirstLetter(_vm.status)) + "Standar"), 
                params: {subStatus: 'register'}, 
                query: {
                    'jenis-pemeriksaan' : _vm.jenisPemeriksaanFilter,
                    'tanggal-mulai' : _vm.tMulai,
                    'tanggal-akhir' : _vm.tAkhir
                }
            }}},[_vm._v(" Standar  "),_c('b-badge',{attrs:{"variant":"light-success"}},[_vm._v(" "+_vm._s(_vm.cStandar)+" ")])],1),_c('b-nav-item',{attrs:{"active":_vm.subStatus == 'titipProcessing' ? true : false,"to":{
                name: ("pemeriksaanPemeriksaan" + (_vm.capitalizeFirstLetter(_vm.status)) + "TitipProcessing"), 
                params: {subStatus: 'susul'}, 
                query: {
                    'jenis-pemeriksaan' : _vm.jenisPemeriksaanFilter,
                    'tanggal-mulai' : _vm.tMulai,
                    'tanggal-akhir' : _vm.tAkhir
                }
            }}},[_vm._v(" Titip Processing  "),_c('b-badge',{attrs:{"variant":"light-info"}},[_vm._v(" "+_vm._s(_vm.cTitipProcessing)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }