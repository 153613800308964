<template>
    <div class="col-12">
        <hr>
        <b-nav card-header tabs>
            <b-nav-item :active="subStatus == 'standar' ? true : false"
                :to="{
                    name: `pemeriksaanPemeriksaan${capitalizeFirstLetter(status)}Standar`, 
                    params: {subStatus: 'register'}, 
                    query: {
                        'jenis-pemeriksaan' : jenisPemeriksaanFilter,
                        'tanggal-mulai' : tMulai,
                        'tanggal-akhir' : tAkhir
                    }
                }"
            >
                Standar&nbsp;
                <b-badge variant="light-success">
                {{cStandar}}
                </b-badge>
            </b-nav-item>
            <b-nav-item :active="subStatus == 'titipProcessing' ? true : false"
                :to="{
                    name: `pemeriksaanPemeriksaan${capitalizeFirstLetter(status)}TitipProcessing`, 
                    params: {subStatus: 'susul'}, 
                    query: {
                        'jenis-pemeriksaan' : jenisPemeriksaanFilter,
                        'tanggal-mulai' : tMulai,
                        'tanggal-akhir' : tAkhir
                    }
                }"
            >
                Titip Processing&nbsp;
                <b-badge variant="light-info">
                {{cTitipProcessing}}
                </b-badge>
            </b-nav-item>
        </b-nav>
    </div>
</template>

<script>
import {
    BNav, BNavItem, BBadge
} from 'bootstrap-vue'
import {onUpdated, computed} from '@vue/composition-api'

export default {
    setup(props, context){
        const cStandar = computed(() => {
            return props.status == 'diagnosa' ? props.cDiagnosaStandar : props.status == 'selesai' ? props.cSelesaiStandar : props.status == 'favorit' ? props.cFavoritStandar : props.status == 'semua' ? props.cSemuaStandar : 0
        })

        const cTitipProcessing = computed(() => {
            return props.status == 'diagnosa' ? props.cDiagnosaTitipProcessing : props.status == 'selesai' ? props.cSelesaiTitipProcessing : props.status == 'favorit' ? props.cFavoritTitipProcessing : props.status == 'semua' ? props.cSemuaTitipProcessing : 0
        })

        context.emit('subStatus', props.subStatus)
        onUpdated( () => {
            context.emit('subStatus', props.subStatus)
        })

        return {cStandar, cTitipProcessing}
    },
    methods: {
        capitalizeFirstLetter (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    },
    props: {
        subStatus: String,
        jenisPemeriksaanFilter: String,
        tMulai: String,
        tAkhir: String,
        cDiagnosaStandar: Number,
        cDiagnosaTitipProcessing: Number,
        cSelesaiStandar: Number,
        cSelesaiTitipProcessing: Number,
        cFavoritStandar: Number,
        cFavoritTitipProcessing: Number,
        cSemuaStandar: Number,
        cSemuaTitipProcessing: Number,
        status: String
    },
    components: {
        BNav, BNavItem, BBadge
    },
}
</script>
